<template>

    <div v-if="!isLoader" class="icon_container">
        <div class="container">
            <div class="row">

                <div class="col-md-6 col-sm-6 col-xs-12">

                    <div class="col-md-4">

                        <div class="row">

                        </div>

                    </div>

                    <div class="col-md-8">

                        <div class="row">

                            <div class="col-md-2 ">
                                <span class=" skeletion-icon"></span>
                            </div>

                            <div class="col-md-10">
                                <strong class="skeletion-title-one"></strong>
                                <strong class="skeletion-title-two"></strong>

                                <br>
                            </div>

                        </div>

                    </div>



                </div>

                <div class="col-md-6 col-sm-6 col-xs-12">

                    <div class="col-md-4">

                        <div class="row">

                        </div>

                    </div>

                    <div class="col-md-8">

                        <div class="row">

                            <div class="col-md-2">
                                <span class="skeletion-icon"></span>
                            </div>

                            <div class="col-md-10">
                                <strong class="skeletion-title-one"></strong>
                                <strong class="skeletion-title-two"></strong>

                                <br>
                            </div>

                        </div>

                    </div>



                </div>

            </div>
        </div>
    </div>

    <div v-else class="icon_container">

        <div class="container">

            <div class="row">

                <div class="col-md-6 col-sm-6 col-xs-12">

                    <div class="col-md-4">

                        <div class="row">



                        </div>

                    </div>

                    <div class="col-md-8">

                        <div class="row">

                            <div class="col-md-2"><i class="fas fa-truck fa-3x pull-right"></i></div>

                            <div class="col-md-10"><strong>Delivery System</strong>

                                <br>On All Orders
                            </div>

                        </div>

                    </div>



                </div>

                <div class="col-md-6 col-sm-6 col-xs-12">

                    <div class="col-md-4">

                        <div class="row">

                        </div>

                    </div>

                    <div class="col-md-8">

                        <div class="row">

                            <div class="col-md-2"><i class="fas fa-gift fa-3x pull-right"></i></div>

                            <div class="col-md-10"><strong>Click Here for Wholesale</strong>

                                <br>Wholesale List

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>
</template>
<script>
export default {
    name: 'ServiceMenu',
    props: {
        msg: String
    },
    data() {
        return {
            isLoader: false
        };
    },
    async mounted() {
        setTimeout(function () {
            this.isLoader = true
        }.bind(this), 500)
    },
}
</script>

<style>
.skeletion-icon {
    display: block;
    width: 50px;
    height: 32px;
    border-radius: 4px;
    background-color: #e0e0e0;
    background-image: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    position: absolute;
    right: 0;
}

.skeletion-title-one {
    display: block;
    width: 100px;
    height: 7px;
    margin-top: 5px;
    background-color: #e0e0e0;
    background-image: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    border-radius: 10px;
}

.skeletion-title-two {
    display: block;
    width: 70px;
    height: 7px;
    background-color: #e0e0e0;
    background-image: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    margin-top: 10px;
    border-radius: 10px;
}

@keyframes loading {
    0% {
        background-position: 200% 0;
    }

    100% {
        background-position: -200% 0;
    }
}
</style>