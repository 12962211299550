<template>

    <nav v-if="!isLoader">
        <div class="navbar navbar-main navbar-default" role="navigation">
            <ul class="skeletion-menu">
                <li class="skeletion-menu-list"></li>
                <li class="skeletion-menu-list"></li>
                <li class="skeletion-menu-list"></li>
                <li class="skeletion-menu-list"></li>
                <li class="skeletion-menu-list"></li>
                <li class="skeletion-menu-list"></li>
                <li class="skeletion-menu-list"></li>
                <li class="skeletion-menu-list"></li>
            </ul>
        </div>
    </nav>

    <nav v-else class="navbar navbar-main navbar-default" role="navigation">

        <div class="container">
            <div class="collapse navbar-collapse navbar-ex1-collapse">
                <ul class="nav navbar-nav">
                    <li v-for="cat in categories" :key="cat.id" :class="hoverId === cat.id ? 'open' : ''"
                        @mouseover="open(cat.id)" @mouseleave="open(0)" class="dropdown megaDropMenu">
                        <router-link tag="a" class="dropdown-toggle main-title-nav" data-hover="dropdown"
                            data-delay="300" data-close-others="true" aria-expanded="false"
                            :to="cat.slug === 'home' ? '/' : { name: 'category', params: { slug: cat.slug } }">{{
                                cat.name }}
                        </router-link>
                        <ul class="dropdown-menu">
                            <li v-for="sub_cat in cat.sub_category" :key="sub_cat.id" class="col-sm-2 col-xs-12">
                                <ul class="list-unstyled">
                                    <li style="padding: 0;">
                                        <router-link style="font-weight: 600" class="main-title-sub-nav"
                                            :to="{ name: 'category', params: { slug: sub_cat.slug }, }">
                                            {{ sub_cat.name }}
                                        </router-link>
                                        <!--<a style="font-weight: 600" href="https://eurasiasupplies.com/face">{{sub_cat.name}}</a>-->
                                    </li>
                                    <li v-for="sub_sub_cat in sub_cat.sub_sub_category" :key="sub_sub_cat.id">
                                        <router-link class="main-title-sub-nav"
                                            :to="{ name: 'category', params: { slug: sub_sub_cat.slug }, }">
                                            {{ sub_sub_cat.name }}
                                        </router-link>
                                        <!--<a href="https://eurasiasupplies.com/bb-and-cc-cream1">{{sub_sub_cat.name}}</a>-->
                                    </li>

                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li class="dropdown" :class="isBrand ? 'open' : ''" @mouseover="isBrand = true"
                        @mouseleave="isBrand = false">
                        <a class="dropdown-toggle" data-hover="dropdown" data-delay="300" data-close-others="true"
                            aria-expanded="false">BRAND</a>
                        <ul class="dropdown-menu" style="height:350px;overflow-x:hidden;overflow-y:scroll;left:-50px;">
                            <li style="padding-left: 15px">
                                <input type="text" class="form-control" placeholder="Enter brand" v-model="brandSearch">
                            </li>
                            <span v-if="brandsByTextFilter.length > 0">
                                <li v-for="spcbrand in brandsByTextFilter" :key="spcbrand.id">
                                    <router-link class="main-title-sub-nav"
                                        :to="{ name: 'brand', params: { slug: spcbrand.slug }, }">{{ spcbrand.name
                                        }}</router-link>
                                </li>
                            </span>
                            <span v-else>
                                <li v-for="spcbrand in brands" :key="spcbrand.id">
                                    <router-link class="main-title-sub-nav"
                                        :to="{ name: 'brand', params: { slug: spcbrand.slug }, }">{{ spcbrand.name
                                        }}</router-link>
                                </li>
                            </span>
                        </ul>
                    </li>
                </ul>

            </div>
            <!-- /.navbar-collapse -->

        </div>

    </nav>
</template>
<script>
/*import Dropdown from './Dropdown'*/
// import FixedHeader from "vue-fixed-header";
import { mapGetters } from "vuex";

export default {
    name: "NavMenu",
    components: {
        // FixedHeader,
        /*Dropdown*/
    },
    data() {
        return {
            alphabet: "#",
            brandSearch: "",
            showBrandMenu: false,
            isOpen: false,
            navigation: [],
            isActive: true,
            subNav: [],
            subSubNav: [],
            isSubNav: false,
            isSubSubNav: false,
            mainMobileNav: false,
            hoverId: '',
            isBrand: false,
            brandList: [],
            isLoader: false
        };
    },
    computed: {
        ...mapGetters(["categories", "brand", "brands", "brandsByTextFilter"]),
    },
    mounted() {
        this.$store.dispatch("getCategories");
        this.$store.dispatch("getBrands");
        this.$store.dispatch("getBrandByTextFiltering", "#");
        // console.log("Cate ====>", this.categories);
        setTimeout(() => {
            this.categories.forEach((item) => {
                item.open = true;
                item.sub_category.forEach((subItem) => {
                    subItem.open = false;
                    subItem.sub_category = subItem.sub_sub_category;
                });
                this.navigation.push(item);
                //// console.log("navigation =", this.navigation);
            });
        }, 100);
    },

    async mounted() {
        setTimeout(function () {
            this.isLoader = true
        }.bind(this), 500)
    },

    watch: {
        alphabet(newVlue, oldValue) {
            this.$store.dispatch("getBrandByTextFiltering", newVlue);
            // console.log("New=", newVlue, "Old=", oldValue);
        },
        brandSearch(val) {
            this.$store.dispatch("getBrandByTextFiltering", val);
        },
        $route() {
            this.showBrandMenu = false;
            this.isSubNav = false;
            this.isSubSubNav = false;
            this.mainMobileNav = false;
        },
    },
    methods: {
        open(id) {
            this.hoverId = id;
        },
        showSubMenu(item) {
            this.subNav = item;
            this.isSubNav = true;
        },
        showSubSubMenu(item) {
            this.subSubNav = item;
            this.isSubSubNav = true;
            // console.log("iiii", item);
        },
        hideSubNave() {
            this.isSubNav = false;
        },
        hideSubSubNave() {
            this.isSubSubNav = false;
        },
        hideBrandMenu() {
            this.showBrandMenu = false;
        },
        hideMainNav() {
            this.mainMobileNav = false;
        },
        mouseOver(data) {
            //// console.log("data hover =", data);
            this.alphabet = data;
            //this.$store.dispatch('getBrandByTextFiltering',this.alphabet);
            //// console.log("data By Text =", this.brandsByTextFilter);
        },
        openMbNav() {
            this.mainMobileNav = true;
            this.$store.dispatch("sohwMobileMenu");
        },
        hideMbNav() {
            this.$store.dispatch("sohwMobileMenuHide");
        },
    },
};
</script>
<style>
.navbar {
    min-height: 47px !important;
}

ul.brand-drop {
    height: 300px;
    overflow: scroll;
}

.special_type_background_1 {

    background: url("https://eurasiasupplies.com//application/views/module/category_background/ee1.png") no-repeat;

    background-position: center center;

}

.special_type_background_2 {

    background: url("https://eurasiasupplies.com//application/views/module/category_background/ee12221.png") no-repeat;

    background-position: center center;

}

a.dropdown-toggle.main-title-nav:hover {
    background: #000 !important;
}

a.main-title-sub-nav.router-link-exact-active.router-link-active {
    background: red !important;
    color: #0A0A0A !important;
}

a.main-title-sub-nav.router-link-exact-active.router-link-active:hover {
    background: red !important;
    color: #0A0A0A !important;
}

a.main-title-sub-nav.router-link-exact-active.router-link-active:focus {
    background: red !important;
    color: #0A0A0A !important;
}

.navbar .navbar-nav {
    display: inline-block;
    float: none;
    vertical-align: top;
}

.navbar .navbar-collapse {
    text-align: center;
}

.skeletion-menu {
    display: flex;
    width: 93%;
    margin: 10px auto;
    justify-content: center;
}

.skeletion-menu-list {
    width: 100px;
    height: 0px;
    background-color: red;
    margin: 0px 30px;
    padding: 15px;
    border-radius: 4px;
    background-color: #e0e0e0;
    background-image: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
}

@keyframes loading {
    0% {
        background-position: 200% 0;
    }

    100% {
        background-position: -200% 0;
    }
}
</style>